import React from 'react';
import { graphql } from 'gatsby';
import { FieldValues } from 'react-hook-form';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import Color from '../../../primitives/colors';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import FormProvider from '../../../primitives/form-provider';
import RowSet from '../../../primitives/grid/row-set';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Input from '../../../primitives/input';
import StyledButton from '../../../primitives/styled-button';
import FormAlert from '../../../primitives/form-alert';
import ColSet from '../../../primitives/grid/col-set';
import MediaItemWithDecoration from '../../../primitives/media-item-with-decoration';
import useQueryParams from '../../../../hooks/use-query-params';
import Divider from '../../../primitives/divider';

export type LoginFormProps = Queries.LoginFormPropsQuery;
const useWorkspaceCookie = (): [string|null, (workspace: string) => void] => {
    const [workspace, setWorkspace] = React.useState<string|null>(null);

    React.useEffect(() => {
        const cookieValue = document.cookie.split('; ').find(row => row.startsWith('valid_workspaces='));
        if (cookieValue) {
            setWorkspace(cookieValue.split('=')[1]);
        }
    }, []);

    const set = (workspace: string): void => {
        document.cookie = `valid_workspaces=${workspace}; Secure`;
        setWorkspace(workspace);
    };

    return [workspace, set];
};

const LoginForm = ({
    title,
    text,
    mediaItem,
}: LoginFormProps): JSX.Element => {
    const [workspace, setWorkspace] = useWorkspaceCookie();
    const query = useQueryParams();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<string[]>([]);

    const onSubmit = React.useCallback((form: FieldValues) => {
        setErrors([]);
        setIsSubmitting(true);
        const workspaceInputValue = form.workspace as string;

        fetch(`https://workspace-settings.onuptick.com/${workspaceInputValue}`)
            .then((response) => {
                if (!response.ok) {
                    return response.text().then((text) => { throw new Error(text); });
                }
                return response;
            })
            .then(() => {
                setWorkspace(workspaceInputValue);
                const customerServer = `https://${workspaceInputValue}.onuptick.com/`;

                if (query?.sender === 'zendesk') {
                    window.location.href = `${customerServer}sign-into-support/?return_to=${(query?.return_to as string) || 'https://support.uptickhq.com/hc/'}`;
                    return null;
                }

                if (query?.return_to?.startsWith('https://bi.onuptick.com/')) {
                    window.location.href = `${customerServer}bi/authenticate/`;
                    return null;
                }

                window.location.href = `${customerServer}${(query?.return_to as string) || ''}`;
                return null;
            })
            .catch(({ message }) => {
                setIsSubmitting(false);
                setErrors([
                    message,
                ]);
            });
    }, [query?.return_to, query?.sender, setWorkspace]);

    return (
        <div css={{
            position: 'relative',
            overflow: 'hidden',
        }}
        >
            <Container css={{
                marginTop: '100px',
                marginBottom: '100px',
                minHeight: '460px',
                [breakpoints.mb]: {
                    minHeight: 'unset',
                    marginTop: '80px',
                    marginBottom: '80px',
                },
            }}
            >
                <Row>
                    <Col breakpoints={{ dt: { span: 6 } }}>
                        {query && query.sender && query.sender === 'zendesk' && (
                            <div css={{
                                marginBottom: '32px',
                            }}
                            >
                                <Typography
                                    fontSize={{
                                        dt: 1322,
                                    }}
                                    css={{
                                        marginBottom: '32px',
                                        padding: '16px',
                                        borderRadius: borderRadius.small,
                                    }}
                                    backgroundColor="primaryOrange"
                                    color="shadesWhite"
                                >
                                    This Uptick Learning content requires you to be signed in. You will be
                                    redirected to the content after signing in.
                                </Typography>
                                <Divider />
                            </div>
                        )}
                        <div>
                            <Typography
                                fontSize={{
                                    dt: 3234,
                                }}
                                css={{
                                    [breakpoints.mb]: {
                                        textAlign: 'center',
                                    },
                                }}
                                fontWeight="light"
                            >
                                {title}
                            </Typography>
                            <Typography
                                fontSize={{
                                    dt: 1822,
                                    mb: 1322,
                                }}
                                css={{
                                    marginTop: '16px',
                                    opacity: '0.7',
                                    [breakpoints.mb]: {
                                        textAlign: 'center',
                                    },
                                }}
                            >
                                {text}
                            </Typography>
                        </div>
                        <FormProvider
                            values={{
                                workspace: workspace || undefined,
                            }}
                            onSubmit={onSubmit}
                            disabled={isSubmitting}
                        >
                            <Color
                                backgroundColor="grey50"
                                css={{
                                    borderRadius: borderRadius.medium,
                                    marginTop: '28px',
                                }}
                            >
                                <div css={{ padding: '10px' }}>

                                    <RowSet breakpoints={{ dt: { between: 10 } }}>
                                        <ColSet
                                            breakpoints={{ dt: { between: 10 } }}
                                            css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Input name="workspace" type="text" placeholder="workspace" required />
                                            <div>
                                                .onuptick.com
                                            </div>
                                        </ColSet>
                                    </RowSet>
                                </div>
                            </Color>
                            <div>
                                <StyledButton
                                    css={{
                                        marginTop: '20px',
                                    }}
                                    type="submit"
                                    loading={isSubmitting}
                                    className="mx-auto d-block"
                                >
                                    Sign In
                                </StyledButton>
                            </div>
                        </FormProvider>
                        {errors.map(error => (
                            <div
                                css={{
                                    marginTop: '20px',
                                }}
                                key={error}
                            >
                                <FormAlert>{error}</FormAlert>
                            </div>
                        ))}
                    </Col>
                    <Col breakpoints={{ dt: { span: 6 }, mb: { span: 4, hidden: true } }}>
                        <div css={{
                            margin: 'auto 0',
                            paddingLeft: '12%',
                            [breakpoints.tb]: {
                                width: '118%',
                            },
                            [breakpoints.mb]: {
                                marginLeft: '0',
                                width: '100%',
                                paddingLeft: '12%',
                            },
                        }}
                        >
                            <MediaItemWithDecoration
                                css={{
                                    height: '100%',
                                }}
                                objectFit="cover"
                                autoPlay
                                muted
                                objectPosition="center center"
                                orientation="decoration-on-left"
                                item={mediaItem}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LoginForm;

export const query = graphql`
    fragment LoginFormProps on DatoCmsLoginForm {
        title
        text
        mediaItem {
            ...MediaItemWithDecorationProps
        }
    }
`;
